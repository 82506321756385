import React from "react";
import styled from "styled-components/macro";

const Sent = styled.div`
  display: flex;
  align-items: left;

  & .chat {
    display: block;
    color: #646464;
    padding: 5px;
  }

  & .msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    & .bubble {
      float: right;
      max-width: 75%;
      width: auto;
      height: auto;
      display: block;
      background: #dcf8c6;
      border-radius: 5px;
      position: relative;
      margin: 10px 25px 3px 0px;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

      & .follow {
        margin: 2px 25px 3px 0px;
        background: #dcf8c6;
        float: right;
      }

      & .txt {
        padding: 8px 0 8px 0;
        width: 100%;
        .name {
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          padding: 0 0 0 15px;
          margin: 0 0 4px 0;
          color: #3498db;
          span {
            font-weight: normal;
            color: #2ecc51;
            overflow: hidden;
          }
        }
        .message {
          font-size: 14px;
          font-weight: 100;
          padding: 0 15px 0 15px;
          margin: auto;
          color: #2b2b2b;
          display: table;
        }
        .timestamp {
          font-size: 11px;
          margin: auto;
          padding: 0 15px 0 0;
          display: table;
          float: right;
          position: relative;
          text-transform: uppercase;
          color: #999;
        }
      }
      & .bubble-arrow {
        position: absolute;
        top: 0px;
        bottom: 20px;
        left: auto;
        right: 4px;
        float: right;
      }
      & .bubble-arrow:after {
        content: "";
        position: absolute;
        border-top: 15px solid #f6f6f6;
        border-left: 15px solid transparent;
        border-radius: 4px 0 0 0px;
        width: 0;
        height: 0;
        //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        //looks like I can't give a shadow to the arrows
      }
      & .bubble-arrow.alt:after {
        border-top: 15px solid #dcf8c6;
        transform: scaleX(-1);
      }
    }
  }
`;

Sent.Follow = styled.div`
  &.follow {
    margin: 2px 0 3px 25px;
  }
  & .msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    & .bubble {
      float: right;
      max-width: 75%;
      width: auto;
      height: auto;
      display: block;
      background: #dcf8c6;
      border-radius: 5px;
      position: relative;
      margin: 10px 25px 3px 0px;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

      & .follow {
        margin: 2px 25px 3px 0px;
        background: #dcf8c6;
        float: right;
      }

      & .txt {
        padding: 8px 0 8px 0;
        width: 100%;
        .name {
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          padding: 0 0 0 15px;
          margin: 0 0 4px 0;
          color: #3498db;
          span {
            font-weight: normal;
            color: #2ecc51;
            overflow: hidden;
          }
        }
        & .message {
          font-size: 14px;
          font-weight: 100;
          padding: 0 15px 0 15px;
          color: #2b2b2b;
          display: table;
        }
        & .timestamp {
          font-size: 11px;
          margin: auto;
          padding: 0 15px 0 0;
          display: table;
          float: right;
          position: relative;
          text-transform: uppercase;
          color: #999;
        }
      }
    }
  }
`;

const MessageSent = ({ name, chat, message, time }) => {
  return (
    <>
      {name != null ? (
        <Sent>
          <div className="msg">
            <div className="bubble alt">
              <div className="txt">
                <span className="name alt">{name}</span>
                <span className="chat">{chat}</span>

                <p className="message">{message}</p>
                <span className="timestamp">{time}</span>
              </div>
              <div className="bubble-arrow alt"></div>
            </div>
          </div>
        </Sent>
      ) : (
        <Sent.Follow>
          <div className="msg">
            <div className="bubble">
              <div className="txt">
                <p className="message">{message}</p>
                <span className="timestamp">{time}</span>
              </div>
            </div>
          </div>
        </Sent.Follow>
      )}
    </>
  );
};

export default MessageSent;
