import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSession } from "../../Context";
import { message } from "antd";
import { Loading } from "../../Components";
import api from "../../Services/api";

message.config({
  maxCount: 1,
});

export default function Index({ path, component, ...rest }) {
  const { session, login } = useSession();
  const [sessionIsValid, setSessionIsValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let token;
    if (session && session.accessToken) token = session.accessToken;
    else token = localStorage.getItem("accessToken");

    const config = {
      headers: { authorization: token },
    };

    api
      .get("/verify", config)
      .then((response) => {
        const data = response.data;
        login({ user: data.user_name, accessToken: data.accessToken });
        setSessionIsValid(true);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setSessionIsValid(false);
      })
      .finally(() => setLoading(false));
    //eslint-disable-next-line
  }, []);

  if (loading) return Loading;
  else {
    if (sessionIsValid)
      return <Route path={path} component={component} {...rest} />;
    return <Redirect to="/login" />;
  }
}
