import React from "react";
import styled from "styled-components/macro";
import { Navbar, Notification } from "../../Components";

export default function Base({ children }) {
  return (
    <>
      <Navbar />
      <PageWrapper>
        <Notification />
        {children}
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  width: 78vw;
  margin-left: 20vw;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100vw;
  }
`;
