import styled from "styled-components/macro";

const Filters = styled.div`
  padding: 4%;
  background-color: #f2efea;
  width: 40vw;
  display: flex;

  & .title {
    font-weight: bold;
  }

  & .subtitle {
    color: #aaaaaa;
    margin: 0;
  }

  @media screen and (max-width: 660px) {
    padding: 4%;
    background-color: #ffffff;
    width: 100vw;
  }
`;

export default Filters;
