import React, { useContext, useState, useEffect } from "react";
import { useSession } from ".";
import api from "../Services/api";
import { message } from "antd";

const NotificationsContext = React.createContext();

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }) {
  const { session } = useSession();
  const [notifications, setNotifications] = useState([]);

  function clearNotifications() {
    if (notifications.length > 0) {
      api
        .put("/notification", notifications, config)
        .then((response) => {
          if (response?.data?.data) {
            setNotifications(response.data.data);
          }
          if (response?.data?.message)
            return message.success(response.data.message);
          return message.success("Sucesso ao limpar notificações");
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            return message.error(error?.response?.data?.message);
          return message.error("Não foi possível limpar notificações");
        });
    } else {
      return message.warning("Sem notificações");
    }
  }

  const config = {
    headers: {
      authorization: session.accessToken,
    },
  };

  useEffect(() => {
    return api
      .get("/notification", config)
      .then((response) => {
        setNotifications(response.data.data.rows);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ||
            "Não foi possível buscar notificações"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ notifications, clearNotifications }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
