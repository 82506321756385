import { React, useState, useEffect } from 'react'
import {
  List,
  Input,
  Modal,
  Table,
  message
} from 'antd'
import { Container, Card, Filters, Side } from '../../Components/UI'
import api from '../../Services/api'
import { useSession } from '../../Context'
import styled from 'styled-components'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { MdPeopleOutline } from 'react-icons/md'
import { AiOutlineDelete } from 'react-icons/ai'

const { confirm } = Modal
function Users(props) {
  const { id } = props.match.params
  const { session } = useSession()
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const [filtered, setFiltered] = useState([])
  const [group, setGroup] = useState({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [userInfractions, setUserInfractions] = useState([])

  const config = {
    headers: {
      authorization: session.accessToken
    }
  }

  function deleteInfraction(id) {
    try {
      api.delete(`/notification/${id}`, config).then(res => {
        const aux = userInfractions.filter(e => e.id !== id);
        setUserInfractions(aux);

        if (res?.data?.message) {
          return message.success(res.data.message);
        }
        return message.success('Advertência apagada com sucesso.');
      })
    } catch (error) {
      if (error?.response?.data?.message)
        return message.error(error?.response?.data?.message)
      return message.error('Não foi possível apagar advertência')
    }
  }

  const columns = [
    {
      title: 'Regra',
      dataIndex: 'rule',
      key: 'rule'
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'age',
      render: date => moment(date).format('DD/MM/YYYY')
    },
    {
      title: 'Grupo',
      dataIndex: 'chatName',
      key: 'chatName'
    },
    {
      title: 'Ação',
      key: 'action',
      dataIndex: 'id',
      render: id => (
        <AiOutlineDelete
          size={22}
          style={{ color: '#F55C47', cursor: 'pointer' }}
          onClick={() => showDeleteConfirm(id)}
        />
      )
    }
  ]

  useEffect(() => {
    if (id) {
      return api
        .get(`/group/${id}`, config)
        .then(response => {
          setFiltered(response.data.groups.users)
          setUsers(response.data.groups.users)
          setGroup(response.data.groups)
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message || 'Não foi possível buscar usuários'
          )
        })
    }
    return api
      .get('/user', config)
      .then(response => {
        const fakeGroup = {
          name: 'Todos os usuários',
          numMembers: response.data.users.length
        }
        setFiltered(response.data.users)
        setUsers(response.data.users)
        setGroup(fakeGroup)
      })
      .catch(error => {
        message.error(
          error?.response?.data?.message || 'Não foi possível buscar usuários'
        )
      })

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser) {
      return api
        .get(`/notification/${currentUser?.id}`, config)
        .then(response => {
          setUserInfractions(response.data.data.rows)
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message ||
              'Não foi possível buscar notificações do usuário'
          )
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  function handleSearch(value) {
    setSearch(value)

    setFiltered(
      users.filter(user => {
        if (value === '') return user
        return (
          user.name.toLowerCase().includes(value.toLowerCase()) ||
          user.number.toString().includes(value)
        )
      })
    )
  }

  function toggleModal(user) {
    setIsModalVisible(isModalVisible => !isModalVisible)
    setCurrentUser(user)
  }

  function showDeleteConfirm(id) {
    confirm({
      title: 'Deseja realmente remover essa advertência?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Apagar',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        deleteInfraction(id)
      }
    })
  }

  return (
    <>
      <Side>
        <Filters>
          <div>
            <h1 className="title">{group.name}</h1>
            <Users.NumMembers>
              <MdPeopleOutline size={25} />
              <span>{group.numMembers}</span>
            </Users.NumMembers>
            <h3 className="title">Buscar Usuário:</h3>
            <Input
              className="search-input"
              placeholder="Buscar..."
              onChange={e => handleSearch(e.target.value, 0)}
              value={search}
            />
          </div>
        </Filters>
        <MessagesContainer>
          <h1 className="title">Participantes</h1>
          <GroupContainer>
            <Card>
              <List
                itemLayout="horizontal"
                dataSource={filtered}
                pagination={{
                  pageSize: 20
                }}
                renderItem={item => (
                  <SListItem onClick={() => toggleModal(item)}>
                    <List.Item.Meta
                      title={<h3>{item.name}</h3>}
                      description={<p>{item.number}</p>}
                    />
                  </SListItem>
                )}
              />
            </Card>
          </GroupContainer>
        </MessagesContainer>
      </Side>
      <Modal
        visible={isModalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        title={currentUser?.name}
        zIndex={999}
      >
        <Table dataSource={userInfractions} columns={columns} />
      </Modal>
    </>
  )
}

const MessagesContainer = styled(Container)`
  height: 100vh;
  overflow-y: scroll;

  @media screen and (max-width: 660px) {
    overflow-y: hidden;
  }
`

const GroupContainer = styled(Container)`
  padding: 3% 5% 5% 5%;
`

Users.NumMembers = styled.div`
  display: flex;
  font-size: 18px;
  margin-bottom: 10px;

  & > span {
    margin-left: 5px;
  }
`

const SListItem = styled(List.Item)`
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`

export default Users
