import React from "react";
import styled from "styled-components/macro";

export default function SearchItem({
  title,
  infoLeft,
  infoRight,
  LeftIcon,
  leftText,
  RightIcon,
  ...rest
}) {
  return (
    <SearchItem.Wrapper {...rest}>
      <SearchItem.Title> {title}</SearchItem.Title>
      <SearchItem.InfoContainer>
        <SearchItem.Info>
          {" "}
          {LeftIcon ? <LeftIcon /> : null}
          {infoLeft}
          {leftText}{" "}
        </SearchItem.Info>
      </SearchItem.InfoContainer>
    </SearchItem.Wrapper>
  );
}

SearchItem.Wrapper = styled.div`
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ selected, theme }) => (selected ? "white" : theme.textGrey)};
  background-color: ${({ selected, theme }) =>
    selected ? theme.backgroundGrey : null};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundGrey};
    color: white;
  }
`;
SearchItem.Title = styled.h3`
  font-weight: bold;
`;
SearchItem.InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
SearchItem.Info = styled.p`
  font-weight: 900;
`;
