import { Input, Form } from "antd";
import React from "react";
import styled from "styled-components";

export default function ConfigItem({ value, onChange, name, label, ...rest }) {
  return (
    <SForm initialValues={{ [name]: value }} {...rest}>
      <Form.Item name={name} label={label}>
        <SInput name={name} placeholder={value} onChange={onChange} />
      </Form.Item>
    </SForm>
  );
}
const SInput = styled(Input)`
  border-radius: 4px;
  width: 100%;
`;
const SForm = styled(Form)`
  width: 100%;
  max-width: 300px;
`;
