import { React, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Form, Input, message, Button } from "antd";
import api from "../../Services/api";
import { Background, Box } from "../../Components/UI";
import { Back, Loading } from "../../Components";
import { AiOutlineLock } from "react-icons/ai";

function Reset() {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    api
      .post("/resetpassword", { ...state, token })
      .then((response) => {
        if (response?.data?.message) history.push("/");
        return message.success(response?.data?.message);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          return message.error(error.response.data.message);

        return message.error(
          "Não foi possível fazer login, tente novamente mais tarde",
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <Background>
      {loading ? <Loading /> : null}
      <Box>
        <Back as={Link} to="/" />
        <h1>Redefinir senha</h1>
        <Form initialValues={{ remember: true }}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Insira a senha!" }]}
          >
            <Input
              prefix={<AiOutlineLock className="site-form-item-icon" />}
              type="password"
              name="password"
              placeholder="Senha"
              size="large"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Por favor, confirme sua senha!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("As senhas digitadas são diferentes!")
                  );
                },
              }),
            ]}
          >
            <Input
              prefix={<AiOutlineLock className="site-form-item-icon" />}
              type="password"
              name="password"
              placeholder="Confirmação de senha"
              size="large"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "230px" }}
              onClick={handleSubmit}
            >
              Alterar senha
            </Button>
          </Form.Item>
        </Form>
      </Box>
    </Background>
  );
}

export default Reset;
