import React, { useEffect, useRef } from 'react'
import { AiFillBell } from 'react-icons/ai'
import { Badge, Dropdown, Menu, Button, Popconfirm, Empty } from 'antd'
import styled from 'styled-components/macro'
import { useNotifications } from '../../Context/notification'
import { TiWarningOutline } from 'react-icons/ti'

const Bell = styled.div`
  color: #adeecf;
  align-items: right;
`

const Align = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  z-index: 999;
`

function dataAtualFormatada(new_date) {
  var data = new Date(new_date),
    dia = data.getDate().toString(),
    diaF = dia.length === 1 ? '0' + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length === 1 ? '0' + mes : mes,
    anoF = data.getFullYear()
  return diaF + '/' + mesF + '/' + anoF
}

export default function Notification() {
  const { notifications, clearNotifications } = useNotifications()
  const menus = useRef()

  useEffect(() => {
    if (notifications.length > 0) {
      menus.current = notifications.map((notification, index) => {
        return (
          <>
            <SMenuItem
              // style={{
              //   display: 'flex',
              //   alignItems: 'center',
              //   whiteSpace: 'none'
              // }}
              key={index}
            >
              <TiWarningOutline style={{ color: 'ffc947', fontSize: '75px' }} />
              {`O(A) participante ${notification.user.name} infringiu a regra ${
                notification.rule
              } no grupo ${notification.chatName} em ${dataAtualFormatada(
                notification.createdAt
              )}`}
            </SMenuItem>
            {index === notifications.length - 1 ? '' : <Menu.Divider />}
          </>
        )
      })
    }
  }, [notifications])

  const menu = () => {
    return (
      <>
      {notifications.length > 0 ? (
        <Menu
        style={{
          width: '400px',
          maxWidth: '90%',
          maxHeight: '350px',
          overflowY: 'auto',
          borderRadius: '4px'
        }}
      >
        {menus.current}
        <div
          className="read-notifications"
          style={{
            marginBottom: '-4px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Popconfirm
            placement="bottom"
            title={'Realmente deseja limpar as notificações'}
            onConfirm={clearNotifications}
            okText="Apagar"
            cancelText="Não"
          >
            <CButton type="primary">Limpar Notificações</CButton>
          </Popconfirm>
        </div>
      </Menu>
      ) : (
        <Empty style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 8px 24px 0px rgb(0 0 0 / 8%)' }} />
      )}
      </>
    )
  }

  return (
    <Align>
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Badge
            count={notifications.length}
            overflowCount={9}
            onClick={e => e.preventDefault()}
          >
            <Bell style={{ cursor: 'pointer' }} >
              <AiFillBell size={40} />
            </Bell>
          </Badge>
        </Dropdown>
    </Align>
  )
}

const CButton = styled(Button)`
  margin-top: 1%;
  margin-bottom: 1%;
  border-radius: 2px;
  width: 50%;
`

const SMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  white-space: none;
  cursor: default;

  &:hover {
    background-color: #FFFFFF;
  }
`
