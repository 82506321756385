import React from "react";
import styled from "styled-components/macro";

const Received = styled.div`
  display: flex;
  align-items: left;

  & .chat {
    display: block;
    color: #646464;
    margin-left: 5px;
  }

  & .msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    & .bubble {
      float: left;
      max-width: 75%;
      height: auto;
      display: block;
      background: #f6f6f6;
      border-radius: 5px;
      position: relative;
      margin: 10px 0 3px 25px;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

      & .txt {
        padding: 8px 0 8px 0;
        min-width: 125px;
        & .name {
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          padding: 0 0 0 15px;
          margin: 0 0 4px 0;
          color: #3498db;
          span {
            font-weight: normal;
            color: #b3b3b3;
            overflow: hidden;
          }
        }
        & .message {
          font-size: 14px;
          font-weight: 100;
          padding: 0 15px 0 15px;
          margin-bottom: 0px;
          color: #2b2b2b;
          display: table;
        }
        & .timestamp {
          font-size: 11px;
          padding: 0 15px 0 0;
          display: table;
          float: right;
          position: relative;
          text-transform: uppercase;
          color: #999;
        }
      }
      & .bubble-arrow {
        position: absolute;
        float: left;
        left: -11px;
        top: 0px;
      }
      & .bubble-arrow:after {
        content: "";
        position: absolute;
        border-top: 15px solid #f6f6f6;
        border-left: 15px solid transparent;
        border-radius: 4px 0 0 0px;
        width: 0;
        height: 0;
        //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        //looks like I can't give a shadow to the arrows
      }
      & .bubble-arrow.alt:after {
        border-top: 15px solid #dcf8c6;
        transform: scaleX(-1);
      }
    }
  }
`;

Received.Follow = styled.div`
  &.follow {
    margin: 2px 0 3px 25px;
  }
  & .msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    & .bubble {
      float: left;
      max-width: 75%;
      height: auto;
      display: block;
      background: #f6f6f6;
      border-radius: 5px;
      position: relative;
      margin: 2px 0 3px 25px;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);

      & .txt {
        padding: 8px 0 8px 0;
        width: 100%;
        min-width: 100px;

        & .name {
          font-weight: 600;
          font-size: 14px;
          display: inline-table;
          padding: 0 0 0 15px;
          margin: 0 0 4px 0;
          color: #3498db;
          span {
            font-weight: normal;
            color: #b3b3b3;
            overflow: hidden;
          }
        }
        & .message {
          font-size: 14px;
          font-weight: 100;
          margin-bottom: 0;
          color: #2b2b2b;
          display: table;
        }
        & .timestamp {
          font-size: 11px;
          margin: auto;
          padding: 0 0px 0 0;
          display: table;
          float: right;
          position: relative;
          text-transform: uppercase;
          color: #999;
        }
      }
    }
  }
`;

const MessageReceived = ({ name, chat, message, time }) => {
  return (
    <>
      {name != null ? (
        <Received>
          <div className="msg">
            <div className="bubble">
              <div className="txt">
                <span className="name">{name}</span>
                <span className="chat">{chat}</span>

                <span className="message">{message}</span>
                <span className="timestamp">{time}</span>
              </div>
              <div className="bubble-arrow"></div>
            </div>
          </div>
        </Received>
      ) : (
        <Received.Follow>
          <div className="msg">
            <div className="bubble">
              <div className="txt">
                <span className="message follow">{message}</span>
                <span className="timestamp">{time}</span>
              </div>
            </div>
          </div>
        </Received.Follow>
      )}
    </>
  );
};

export default MessageReceived;
