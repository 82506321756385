import { React, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Form, Input, Checkbox, message, Button } from 'antd'
import api from '../../Services/api'
import { useSession } from '../../Context/session'
import { Background, Box } from '../../Components/UI'
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai'

function Login() {
  const history = useHistory()
  const [state, setState] = useState({})
  const [remember, setRemember] = useState(false)
  const { login } = useSession()

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault()

    api
      .post('login', { ...state, remember })
      .then(response => {
        login({
          user: response.data.user_name,
          accessToken: response.data.accessToken
        })
        history.push('/')
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          return message.error(error.response.data.message)

        return message.error(
          'Não foi possível fazer login, tente novamente mais tarde',
        )
      })
  }

  function handleRememberChange(e) {
    e.preventDefault()
    setRemember(e.target.checked)
  }

  return (
    <Background>
      <Box>
        <h1>Login</h1>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="name"
            rules={[
              { required: true, message: 'Insira um endereço de e-mail!' }
            ]}
          >
            <Input
              prefix={<AiOutlineUser className="site-form-item-icon" />}
              type="user"
              name="user_name"
              placeholder="Usuário"
              size="large"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Insira a senha!' }]}
          >
            <Input
              prefix={<AiOutlineLock className="site-form-item-icon" />}
              type="password"
              name="password"
              placeholder="Senha"
              size="large"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '230px' }}
              onClick={handleSubmit}
            >
              Entrar
            </Button>
          </Form.Item>
          <Checkbox checked={remember} onChange={handleRememberChange}>
            Lembrar de mim
          </Checkbox>
          <Form.Item>
            <Link className="login-form-forgot" to="/esqueciminhasenha">
              Esqueci minha senha
            </Link>
          </Form.Item>
        </Form>
      </Box>
    </Background>
  )
}

export default Login
