import styled from "styled-components/macro";

const Side = styled.div`
  display: flex;

  @media screen and (max-width: 660px) {
    display: block;
  }
`;

export default Side;
