import React, { createContext, useContext, useState } from "react";

const initialValue = { user: null, accessToken: null };

const SessionContext = createContext(initialValue);

export default function SessionProvider({ children }) {
  const [session, setSession] = useState(initialValue);

  function login({ user, accessToken }) {
    if (!accessToken) return setSession(initialValue);

    // context
    setSession({ user, accessToken: "Bearer " + accessToken });

    // localStorage
    window.localStorage.clear();
    window.localStorage.setItem("accessToken", "Bearer " + accessToken);
  }

  function logout() {
    // context
    setSession(initialValue);

    // localStorage
    window.localStorage.clear();
  }

  return (
    <SessionContext.Provider value={{ session, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
}

function useSession() {
  const session = useContext(SessionContext);
  return session;
}

export { useSession, SessionProvider };
