import styled from "styled-components/macro";

const Container = styled.div`
  padding: 4%;
  width: 100%;

  & .title {
    font-weight: bold;
  }

  & .subtitle {
    color: #aaaaaa;
    margin: 0;
  }

  & .label {
    font-size: 15px;
    margin-bottom: 0;
    color: #aaaaaa;
  }

  & .timePicker {
    display: "flex";
    margin-left: 25px;
    flex-direction: "column";
    margin-bottom: 15px;
  }

  & .buttonContainer {
    width: 250px;
  }

  @media screen and (max-width: 660px) {
    & .buttonContainer {
      width: 100%;
    }
  }
`;

export default Container;
