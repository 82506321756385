import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ConfigItem, Loading } from "../../Components";
import { useSession } from "../../Context";
import api from "../../Services/api";

export default function Config() {
  const { session } = useSession();
  const [configs, setConfigs] = useState({});
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      authorization: session.accessToken,
    },
  };

  useEffect(() => {
    setLoading(true);
    api
      .get("/config", config)
      .then((response) => {
        setConfigs(response.data.configs);
      })
      .catch((error) => {
        if (error?.response?.data?.message)
          return message.error(error?.response?.data?.message);
        message.error("Não foi possível obter configurações do sistema");
      })
      .finally(() => setLoading(false));

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(e) {
    setConfigs({ ...configs, [e.target.name]: e.target.value });
  }

  function handleClick() {
    if (configs) {
      setLoading(true);
      api
        .put("/config", configs, config)
        .then((response) => {
          setConfigs(response.data.configs);
          if (response?.data?.message)
            return message.success(response.data.message);
          return message.success("Configurações alteradas com sucesso");
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            return message.error(error?.response?.data?.message);
          return message.error("Não foi possível alterar configurações");
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <Config.Wrapper>
        <Config.Title>Configurações</Config.Title>
        <Config.ConfigsWrapper>
          {Object.keys(configs).map((key) => (
            <SConfigItem
              key={key}
              value={configs[key]}
              name={key}
              label={key}
              onChange={handleChange}
            />
          ))}
        </Config.ConfigsWrapper>
        <SButton type="primary" onClick={handleClick}>
          Salvar
        </SButton>
      </Config.Wrapper>
    </>
  );
}

Config.Wrapper = styled.div`
  padding: 5%;
`;

Config.Title = styled.h1`
  font-weight: 900;
  color: black;
`;
Config.ConfigsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 1350px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  @media screen and (min-width: 900px) {
    justify-content: space-between;
  }
`;
const SConfigItem = styled(ConfigItem)`
  margin-right: 15px;
`;
const SButton = styled(Button)`
  border-radius: 8px;
  font-weight: 700;
  padding: 0.5rem 1.3rem;
  height: auto;
`;
