import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Checkbox, DatePicker, Button, message } from "antd";
import { MdBlock, MdPeople } from "react-icons/md";
import { Search } from "../../Components";

// PROVISÓRIO
// import {UserSwitchOutlined} from "@ant-design/icons";

function Dashboard() {
  const history = useHistory();
  const [inputValues, setInputValues] = useState({});

  function handleChange(e, dateString, name) {
    if (!e && !dateString)
      return setInputValues({ ...inputValues, [name]: "" });
    if (!dateString) {
      e.preventDefault();
      if (e.target.type === "checkbox") {
        return setInputValues({
          ...inputValues,
          [e.target.name]: e.target.checked,
        });
      }
      return setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value,
      });
    }
    const [day, month, year] = dateString.split("/");
    return setInputValues({
      ...inputValues,
      [name]: new Date(`${month}/${day}/${year}`),
    });
  }

  function handleSelect(name, value) {
    return setInputValues({ ...inputValues, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!(inputValues["acceptText"] || inputValues["acceptMedia"]))
      return message.error(
        "Selecione pelo menos um tipo de mensagem para prosseguir."
      );

    if (inputValues["maxDate"])
      return history.push("/mensagens", {
        ...inputValues,
        maxDate: new Date(
          inputValues["maxDate"].getTime() + 1000 * 60 * 60 * 24
        ),
      });
    return history.push("/mensagens", inputValues);
  }

  return (
    <Dashboard.Wrapper>
      <Dashboard.Title>Mensagens</Dashboard.Title>
      <Dashboard.Subtitle>Filtrar mensagens</Dashboard.Subtitle>
      <Dashboard.SubsectionsContainer>
        <Dashboard.Subsection>
          <Search
            title="Buscar Usuário"
            placeholder="Buscar por usuário..."
            titleIndex="name"
            infoLeftIndex="number"
            RightIcon={MdBlock}
            keyIndex="id"
            backendPath="/user"
            responseIndex="users"
            onSelectItem={handleSelect}
            itemName="user_id"
            itemSelected={inputValues["user_id"]}
          />
        </Dashboard.Subsection>
        <Dashboard.Subsection>
          <Search
            title="Buscar Grupo"
            placeholder="Buscar por grupo..."
            titleIndex="name"
            infoLeftIndex="description"
            infoRightIndex="numMembers"
            RightIcon={MdPeople}
            rightText=" Participantes"
            keyIndex="id"
            backendPath="/group"
            responseIndex="groups"
            onSelectItem={handleSelect}
            itemName="chatId"
            itemSelected={inputValues["chatId"]}
          />
        </Dashboard.Subsection>
      </Dashboard.SubsectionsContainer>
      <Dashboard.SubsectionsContainer2>
        <Dashboard.Subsection>
          <Dashboard.Minortitle>
            Buscar por tipo de mensagem:
          </Dashboard.Minortitle>
          <Dashboard.Checkboxes>
            <SCheckbox name="acceptText" onChange={handleChange}>
              Texto
            </SCheckbox>
            <SCheckbox name="acceptMedia" onChange={handleChange}>
              Mídia
            </SCheckbox>
          </Dashboard.Checkboxes>
        </Dashboard.Subsection>
        <Dashboard.Subsection>
          <Dashboard.Minortitle>Data da pesquisa:</Dashboard.Minortitle>
          <SDatePicker
            format={"DD/MM/YYYY"}
            placeholder="Data inicial"
            onChange={(m, d) => handleChange(m, d, "minDate")}
            name="minDate"
            style={{ marginRight: "5px" }}
          />
          <SDatePicker
            format={"DD/MM/YYYY"}
            placeholder="Data final"
            onChange={(m, d) => handleChange(m, d, "maxDate")}
            name="maxDate"
          />
        </Dashboard.Subsection>
      </Dashboard.SubsectionsContainer2>
      <SButton type="primary" size="large" onClick={handleSubmit}>
        Buscar
      </SButton>
    </Dashboard.Wrapper>
  );
}

export default Dashboard;

Dashboard.Wrapper = styled.div`
  margin-left: 5%;
  height: 100%;
  width: 90%;
`;
Dashboard.Title = styled.h1`
  font-weight: 900;
  padding-top: 5%;
  color: black;
`;
Dashboard.Subtitle = styled.h2`
  font-weight: 600;
  color: black;
`;
Dashboard.SubsectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
Dashboard.Subsection = styled.div`
  flex: 1;
  margin-right: 10%;
  min-width: 150px;
  @media screen and (max-width: 650px) {
    margin-top: 5%;
  }
`;
Dashboard.Minortitle = styled.h3`
  font-weight: 600;
`;
Dashboard.SubsectionsContainer2 = styled(Dashboard.SubsectionsContainer)`
  margin-top: 2%;
  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
Dashboard.Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`;
const SCheckbox = styled(Checkbox)`
  margin-left: 0% !important;
  margin-top: 2%;
  color: black;
  font-weight: 900;
`;
const SButton = styled(Button)`
  margin-bottom: 2%;
  margin-left: 80%;
  border-radius: 8px;
  width: 10%;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    margin-top: 10%;
    width: 50%;
    margin-left: 20%;
  }
`;
const SDatePicker = styled(DatePicker)`
  width: 40%;
`;
