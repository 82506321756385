import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Input, DatePicker, Modal, message } from "antd";
import brasil from "antd/lib/locale/pt_BR";
import { Image, Loading } from "../../Components";
import api from "../../Services/api";
import moment from "moment";
import { useSession } from "../../Context/session";

export default function Images() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();
  const [images, setImages] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const { session } = useSession();

  const config = {
    headers: {
      authorization: session.accessToken,
    },
    params: {
      date,
    },
  };

  useEffect(() => {
    const today = new Date();
    handleDateChange(
      "useless",
      `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}` //nem ideia do porquê, mas é preciso somar 2 no dia e 1 no mês
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = {
      // avaliar isso depois que o back estiver implementado
      date,
    };
    getData(params);
    // eslint-disable-next-line
  }, [date]);

  function getData(params) {
    if (date) {
      setLoading(true);
      api
        .get("/image", { ...config, ...params })
        .then((response) => {
          setImages(response.data.images);
          setFiltered(response.data.images);
        })
        .catch((err) => {
          if (err?.response?.data?.message)
            message.error(err?.response?.data?.message);
          else message.error("Não foi possível buscar imagens.");
        })
        .finally(() => setLoading(false));
    }
  }

  function handleDateChange(date, dateString) {
    const [day, month, year] = dateString.split("/");
    const jsDate = new Date(year, month - 1, day); //por algum motivo para que a data fique correta devemos passar o mês menos 1
    setDate(jsDate);
  }

  function handleSearch(e) {
    const term = e.target.value;
    setSearch(term);
    setFiltered(
      images.filter(
        (element) =>
          element.user_id
            .toString()
            .toLowerCase()
            .includes(term.toString().toLowerCase()) ||
          element.userName
            .toString()
            .toLowerCase()
            .includes(term.toString().toLowerCase()) ||
          element.chatName
            .toString()
            .toLowerCase()
            .includes(term.toString().toLowerCase())
      )
    );
  }

  function toggleModal(imageLink) {
    setIsModalVisible((isModalVisible) => !isModalVisible);
    setCurrentImage(imageLink);
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <Images.Wrapper>
        <SModal
          visible={isModalVisible}
          onOk={toggleModal}
          onCancel={toggleModal}
          title={`${currentImage?.userName} em ${currentImage?.chatName}`}
        >
          <SImage src={currentImage?.url} />
        </SModal>
        <Images.Title>Imagens</Images.Title>
        <FormRow>
          <SInput
            placeholder="Buscar Imagem"
            value={search}
            onChange={handleSearch}
          />
          <SDatePicker
            onChange={handleDateChange}
            defaultValue={moment()}
            placeholder="Selecionar Data"
            format="DD/MM/YYYY"
            locale={brasil}
          />
        </FormRow>
        <Images.Container>
          {/* FICARÁ SIMILAR A ISSO QUANDO ESTIVER DINÂMICO: */}
          {filtered?.map((img) => (
            <Image
              height={300}
              src={img.url}
              name={img.user_id.split("@")[0]}
              group={img.chatName}
              userName={img.userName}
              subtitle={`${new Date(img.createdAt).toLocaleDateString(
                "pt-BR"
              )}`}
              onClick={() => toggleModal(img)}
            />
          ))}
        </Images.Container>
      </Images.Wrapper>
    </>
  );
}

Images.Wrapper = styled.div`
  padding: 4%;
  width: 100%;
`;

Images.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;

  img {
    width: 350px;
    max-width: 80vw;
  }

  @media screen and (max-width: 1150px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    img {
      max-width: 80vw;
    }
  }
`;

Images.Title = styled.h1`
  font-weight: bold;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SInput = styled(Input)`
  & {
    flex: 0.78;
    box-shadow: 1px 8px 24px 0px #ddd;
  }
`;
const SDatePicker = styled(DatePicker)`
  & {
    flex: 0.2;
    box-shadow: 0px 8px 24px 0px #ddd;
  }
`;
const SModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SImage = styled(Image)`
  width: 50vw;
  @media screen and (max-width: 700px) {
    width: 70vw;
  }
`;
