import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Input, message } from "antd";
import { SearchItem } from "../../Components";
import api from "../../Services/api";
import { useSession } from "../../Context/session";

export default function Search({
  title,
  placeholder,
  titleIndex,
  infoLeftIndex,
  infoRightIndex,
  LeftIcon,
  leftText,
  RightIcon,
  keyIndex,
  backendPath,
  responseIndex,
  onSelectItem,
  itemName,
  itemSelected,
}) {
  const { session } = useSession();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const configs = {
    headers: {
      authorization: session.accessToken,
    },
    params: {
      search_term: search,
    },
  };

  useEffect(() => {
    if (search && search !== "" && search !== " ") {
      api
        .get(backendPath, configs)
        .then((response) => {
          setData(response.data[responseIndex]);
        })
        .catch((error) => {
          if (error?.response?.data?.message)
            message.error(error.response.data.message);
        });
    } else setData([]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Search.Title>{title}</Search.Title>
      <SInput
        placeholder={placeholder}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Search.Wrapper>
        <Search.ResultsContainer>
          {data?.map((element) => {
            return (
              <SearchItem
                title={element[titleIndex]}
                infoLeft={element[infoLeftIndex]}
                infoRight={element[infoRightIndex]}
                key={element[keyIndex]}
                LeftIcon={LeftIcon}
                leftText={leftText}
                RightIcon={RightIcon}
                onClick={() => onSelectItem(itemName, element[keyIndex])}
                selected={element[keyIndex] === itemSelected}
              />
            );
          })}{" "}
        </Search.ResultsContainer>
      </Search.Wrapper>
    </>
  );
}

Search.Wrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 5px gray;
`;
Search.Title = styled.h3`
  font-weight: bold;
`;

Search.ResultsContainer = styled.div`
  margin-top: 15px;
  max-height: 254px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const SInput = styled(Input)`
  border-radius: 10px;
`;
