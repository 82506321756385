import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { AiOutlineAppstore, AiOutlineMenu } from "react-icons/ai";
import { FiUsers, FiUser, FiLogOut, FiUserPlus } from "react-icons/fi";
import { BiCog, BiImages, BiBlock } from "react-icons/bi";

import logo from "../../Assets/Logo.svg";
import { useSession } from "../../Context/session";

export default function Navbar() {
  const itemsRef = useRef();
  const history = useHistory();
  const { logout } = useSession();

  // mostra ou esconde os itens da sidebar ao clicar no menu
  function toggleItems() {
    itemsRef.current.classList.contains("sm-hidden")
      ? itemsRef.current.classList.remove("sm-hidden")
      : itemsRef.current.classList.add("sm-hidden");
  }

  return (
    <SidebarWrapper>
      <div className="left">
        <Link to="/">
          <Navbar.Logo src={logo} alt="logotipo" />
        </Link>
        <div className="menu" onClick={toggleItems}>
          <Navbar.ItemWrapper
            to="#"
            alt="Dashboard"
            icon={<AiOutlineMenu size="25px" />}
          />
        </div>
      </div>
      <div className=" items sm-hidden" ref={itemsRef}>
        <div>
          <Navbar.ItemWrapper
            alt="Dashboard"
            icon={<AiOutlineAppstore size="25px" />}
            to="/"
          >
            Dashboard
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/grupos" icon={<FiUsers size="25px" />}>
            Grupos
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/usuarios" icon={<FiUser size="25px" />}>
            Usuários
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/cadastro" icon={<FiUserPlus size="25px" />}>
            Cadastrar Adm
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/configuracoes" icon={<BiCog size="25px" />}>
            Configurações
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/palavras" icon={<BiBlock size="25px" />}>
            Palavras Proibidas
          </Navbar.ItemWrapper>
          <Navbar.ItemWrapper to="/imagens" icon={<BiImages size="25px" />}>
            Imagens
          </Navbar.ItemWrapper>
        </div>
        <div>
          <Navbar.ItemWrapper
            to="#"
            icon={<FiLogOut size="25px" />}
            onClick={() => {
              logout();
              history.push("/login");
            }}
          >
            Sair
          </Navbar.ItemWrapper>
        </div>
      </div>
    </SidebarWrapper>
  );
}

const SidebarWrapper = styled.div`
  width: 20vw;
  height: 100vh;
  color: ${({ theme }) => theme.textOverPrimary};
  background-color: ${({ theme }) => theme.primary};
  padding: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  position: fixed;
  z-index: 10;

  & .menu,
  & .hidden {
    display: none;
  }

  & .items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92%;
  }

  @media screen and (max-width: 1300px) {
    padding: 20px;
  }

  @media screen and (max-width: 1000px) {
    & svg {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100vw;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    height: auto;
    align-items: center;

    & .left {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    & .menu {
      display: block;
    }
    & .menu svg {
      display: block;
    }
    & .items {
      margin-top: 30px;
      flex-direction: column;
      align-items: center;
    }
    & div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sm-hidden {
      display: none;
    }
  }
`;

Navbar.Item = styled(Link)`
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

Navbar.Text = styled.p`
  margin: 0;
  margin-left: 10px;
  color: ${({ theme }) => theme.textOverPrimary};
`;

Navbar.ItemWrapper = ({ children, icon, onClick, to }) => {
  return (
    <Navbar.Item to={to} onClick={onClick}>
      {icon}
      <Navbar.Text>{children}</Navbar.Text>
    </Navbar.Item>
  );
};

Navbar.Logo = styled.img`
  width: 14vw;
  margin: auto;
  margin-bottom: 50px;

  @media screen and (max-width: 900px) {
    width: 20vw;
    margin: 0;
  }

  @media screen and (max-width: 660px) {
    width: 30vh;
  }
  @media screen and (max-width: 550px) {
    width: 25vh;
  }
`;
