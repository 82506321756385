import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Table } from "antd";
import styled from "styled-components/macro";
import api from "../../Services/api";
import { useSession } from "../../Context";
import { Loading } from "../../Components";

export default function ForbiddenWords() {
  const { session } = useSession();
  const [loading, setLoading] = useState(false);
  const [words, setWords] = useState([]);
  const [forbiddenWord, setForbiddenWord] = useState();
  const [search, setSearch] = useState();
  const [filtered, setFiltered] = useState([]);

  const config = {
    headers: {
      authorization: session.accessToken,
    },
  };

  const columns = [
    {
      title: "Palavra",
      dataIndex: "word",
      width: "80%",
      sorter: (a, b) => {
        return a.word > b.word;
      },
      render: (word) => <p key={word}>{word}</p>,
    },
    {
      title: "Deletar",
      dataIndex: "word",
      render: (word) => (
        <SButton type="danger" onClick={() => handleDelete(word)}>
          Deletar
        </SButton>
      ),
    },
  ];

  useEffect(() => {
    getAndSetData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAndSetData() {
    setLoading(true);
    api
      .get("/forbidden", config)
      .then((response) => {
        setWords(response.data.forbidden_words);
        setFiltered(response.data.forbidden_words);
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.message ||
            "Não foi possível buscar lista de palavras proibidas."
        );
      })
      .finally(() => setLoading(false));
  }

  function handleChange(e) {
    setForbiddenWord(e.target.value);
  }

  function handleSearch(e) {
    const term = e.target.value;
    setSearch(term);

    setFiltered(
      words.filter((element) =>
        element.word
          .toString()
          .toLowerCase()
          .trim()
          .includes(term.toString().toLowerCase().trim().replace(/\s/g, ""))
      )
    );
  }

  async function handleSubmit() {
    if (forbiddenWord) {
      setLoading(true);
      api
        .post("/forbidden", { forbidden_word: forbiddenWord }, config)
        .then((response) => {
          getAndSetData();
          message.success(response.data.message);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message || "Não foi possível proibir palavra"
          );
        })
        .finally(() => {
          setForbiddenWord("");
          setLoading(false);
        });
      return;
    }
    return message.error("Digite uma palavra para proibí-la.");
  }

  function handleDelete(word) {
    setLoading(true);
    api
      .delete(`/forbidden/${word}`, config)
      .then(() => {
        getAndSetData();
        message.success("Palavra removida com sucesso.");
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.message || "Não foi possível remover palavra."
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <Wrapper>
        <Wrapper.Title>Palavras proibidas</Wrapper.Title>
        <Wrapper.Section>
          <Wrapper.Subtitle>Proibir palavra</Wrapper.Subtitle>
          <Form>
            <Form.Item label="Palavra">
              <SInput
                placeholder="Digite a palavra que deseja proibir"
                value={forbiddenWord}
                onChange={handleChange}
              />
            </Form.Item>
            <SButton type="primary" htmlType="submit" onClick={handleSubmit}>
              Proibir
            </SButton>
          </Form>
        </Wrapper.Section>
        <Wrapper.Section>
          <Wrapper.Subtitle>Lista de palavras proibidas</Wrapper.Subtitle>
          <Form>
            <Form.Item label="Busca">
              <SInput
                placeholder="Digite a palavra que deseja buscar"
                value={search}
                onChange={handleSearch}
              />
            </Form.Item>
          </Form>
          <STable columns={columns} dataSource={filtered} />
        </Wrapper.Section>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  padding: 5%;
`;
Wrapper.Title = styled.h1`
  font-weight: 900;
  color: black;
`;
Wrapper.Subtitle = styled.h3`
  color: black;
  font-weight: 600;
`;
Wrapper.Section = styled.div`
  margin: 40px 0;
`;
const SInput = styled(Input)`
  border-radius: 8px;
`;
const SButton = styled(Button)`
  border-radius: 8px;
  font-weight: 700;
  padding: 0.5rem 1.3rem;
  height: auto;
`;
const STable = styled(Table)`
  max-width: 100vw;

  .ant-table-cell {
    max-width: 50vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
