import React from "react";
import styled from "styled-components/macro";

export default function Image({ src, height, group, name, userName, subtitle, ...rest }) {
  return (
    <Image.Container {...rest}>
      <Image.Img src={src} alt={name} height={height} />
      <Image.Title>
      {userName}
        <span>&#183;</span>
        {group}
      </Image.Title>
      <Image.Title>
      {name}
      </Image.Title>
      <Image.Subtitle>{subtitle}</Image.Subtitle>
    </Image.Container>
  );
}

Image.Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }

  span {
    margin: 0 5px;
  }
`;

Image.Img = styled.img`
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
`;
Image.Title = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  margin: 0;
`;
Image.Subtitle = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.textSecondary};
`;
