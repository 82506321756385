import React from "react";
import styled from "styled-components/macro";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function SmallLoading() {
  return <SAiOutlineLoading3Quarters size={25} color="#aaa" />;
}

const SAiOutlineLoading3Quarters = styled(AiOutlineLoading3Quarters)`
  width: auto;
  height: auto;
  display: inline-block;
  animation: ${({ time }) => `infinite spin ${time || "0.7s"} ease-in-out`};

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
