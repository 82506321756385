import styled from "styled-components/macro";

const Card = styled.div`
  padding: 2vh;
  background: #fff;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
`;

export default Card;
