import { React, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DatePicker, Checkbox, message } from "antd";
import {
  Container,
  Card,
  Filters,
  Side,
  SmallLoading,
} from "../../Components/UI";
import api from "../../Services/api";
import { MessageReceived, MessageSent, Loading, Image } from "../../Components";
import { MdPeople } from "react-icons/md";
import styled from "styled-components";
import moment from "moment";
import { useSession } from "../../Context";
import Modal from "antd/lib/modal/Modal";

function MessageFilter() {
  const location = useLocation();
  const { session } = useSession();
  const bottomRef = useRef();
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState({});
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState("");
  const [page, setPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loadingMore, setLoadingMore] = useState(false);

  const config = {
    headers: {
      authorization: session.accessToken,
    },
    params: { ...location.state },
  };

  let cont = 0;

  useEffect(() => {
    if (!messages) setMessages(null);

    setLoading(true);
    api
      .get("/message", { ...config, params: { ...location.state, page: 1 } })
      .then((response) => {
        setMessages(response?.data?.messages.reverse());
      })
      .catch((err) => message.error(err?.response?.data?.message))
      .finally(() => setLoading(false));

    if (location.state?.user_id)
      api
        .get(`/user/${location.state?.user_id}`, config)
        .then((response) => setUser(response.data.users))
        .catch((err) =>
          message.error(
            err?.response?.data?.message ||
              "Não foi possível buscar dados do usuário"
          )
        );

    if (location.state?.chatId)
      api
        .get(`/group/${location.state?.chatId}`, config)
        .then((response) => setGroup(response.data.groups))
        .catch((err) =>
          message.error(
            err?.response?.data?.message ||
              "Não foi possível buscar dados do grupo"
          )
        );
    api
      .get("/config", config)
      .then((response) => setConfigs(response.data.configs))
      .catch((err) =>
        message.error(
          err?.response?.data?.message || "Não foi possível obter configurações"
        )
      );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (messages.length < 50)
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  function handleScroll(e) {
    if (e.currentTarget.scrollTop === 0) {
      setLoadingMore(true);
      api
        .get("/message", {
          ...config,
          params: { ...location.state, page: page + 1 },
        })
        .then((response) => {
          const responseMessages = [
            ...response?.data?.messages.reverse(),
            ...messages,
          ].sort((a, b) => b.createdAt - a.createdAt);
          setMessages(responseMessages);
          setPage((oldPage) => oldPage + 1);
        })
        .catch((err) => message.error(err?.response?.data?.message))
        .finally(() => setLoadingMore(false));
    }
  }

  function handleClick(src) {
    setImageUrl(src);
    setModalIsOpen(true);
  }

  return (
    <>
      {loading && <Loading />}
      <Side>
        <Modal
          visible={modalIsOpen}
          onCancel={() => setModalIsOpen(false)}
          onOk={() => setModalIsOpen(false)}
        >
          <Image src={imageUrl} />
        </Modal>
        <Filters>
          <div>
            <h1 className="title">Filtros selecionados</h1>
            {location.state?.user_id && (
              <>
                <h3 className="title">Usuário:</h3>
                <Card>
                  <p className="title">{user?.name}</p>
                  <div style={{ display: "flex" }}>
                    <p>{user?.number}</p>
                  </div>
                </Card>
              </>
            )}
            {location.state?.chatId && (
              <>
                <h3 className="title">Grupo:</h3>
                <Card>
                  <p className="title">{group?.name}</p>
                  <div style={{ display: "flex" }}>
                    <p>{group?.description}</p>
                    <p style={{ marginLeft: "20px" }}>
                      <MdPeople /> {group?.numMembers} participantes
                    </p>
                  </div>
                </Card>
              </>
            )}
            {(location.state?.minDate || location.state?.maxDate) && (
              <h3 className="title" style={{ marginTop: "20px" }}>
                Data:
              </h3>
            )}
            {location.state?.minDate && (
              <>
                <p>De:</p>
                <SDatePicker
                  format={"DD/MM/YYYY"}
                  placeholder="Data inicial"
                  name="minDate"
                  disabled
                  defaultValue={moment(
                    location.state?.minDate || location.state?.maxDate
                  )}
                />
              </>
            )}
            {location.state?.maxDate && (
              <>
                <p>Até:</p>
                <SDatePicker
                  format={"DD/MM/YYYY"}
                  placeholder="Data final"
                  name="maxDate"
                  defaultValue={moment(
                    location.state?.maxDate || location.state?.minDate
                  )}
                  disabled
                />
              </>
            )}
            <>
              <h3 className="title" style={{ marginTop: "20px" }}>
                Tipo:
              </h3>
              <SCheckbox
                name="acceptText"
                checked={location.state?.acceptText}
                disabled
              >
                Texto
              </SCheckbox>
              <SCheckbox
                name="acceptMedia"
                checked={location.state?.acceptMedia}
                disabled
              >
                Mídia
              </SCheckbox>
            </>
          </div>
        </Filters>

        <MessagesContainer onScroll={handleScroll}>
          <div>
            <h1 className="title">Mensagens</h1>
            <div className="loadingMoreContainer">
              {loadingMore && <SmallLoading />}
            </div>
            {messages?.map((message) =>
              message.user.number === configs["numero_responsavel"] ? (
                ((cont = cont + 1),
                cont === 1 ? (
                  <MessageSent
                    name={message.user.name}
                    chat={message.chatName}
                    message={
                      message.isMedia ? (
                        <img
                          src={message.body}
                          width="300"
                          height="300"
                          onClick={() => handleClick(message.body)}
                          style={{ cursor: "pointer", objectFit: "cover" }}
                          alt={`Sent by ${message.user.name}`}
                        />
                      ) : (
                        message.body
                      )
                    }
                    time={new Date(message.createdAt).toLocaleDateString(
                      "pt-BR"
                    )}
                  />
                ) : (
                  <MessageSent
                    chat={message.chatName}
                    message={
                      message.isMedia ? (
                        <img
                          src={message.body}
                          width="300"
                          height="300"
                          onClick={() => handleClick(message.body)}
                          style={{ cursor: "pointer", objectFit: "cover" }}
                          alt={`Sent by ${message.user.name}`}
                        />
                      ) : (
                        message.body
                      )
                    }
                    time={new Date(message.createdAt).toLocaleDateString(
                      "pt-BR"
                    )}
                  />
                ))
              ) : (
                <MessageReceived
                  name={message.user.name}
                  chat={message.chatName}
                  message={
                    message.isMedia ? (
                      <img
                        src={message.body}
                        width="300"
                        height="300"
                        onClick={() => handleClick(message.body)}
                        style={{ cursor: "pointer", objectFit: "cover" }}
                        alt={`Sent by ${message.user.name}`}
                      />
                    ) : (
                      message.body
                    )
                  }
                  time={new Date(message.createdAt).toLocaleDateString("pt-BR")}
                />
              )
            )}
          </div>

          {/* div usada para fazer scroll automático para baixo ao carregar mensagens */}
          <div style={{ height: 30 }} ref={bottomRef} />
        </MessagesContainer>
      </Side>
    </>
  );
}

const MessagesContainer = styled(Container)`
  height: 100vh;
  overflow-y: scroll;

  .loadingMoreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* @media screen and (max-width: 660px) {
    overflow-y: hidden;
  } */
`;

const SDatePicker = styled(DatePicker)`
  width: 40%;
`;
const SCheckbox = styled(Checkbox)`
  margin-left: 0% !important;
  margin-top: 2%;
  color: black;
  font-weight: 900;
`;
export default MessageFilter;
