import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { PrivateRoute } from "./Components";
import {
  Login,
  Groups,
  Users,
  Images,
  MessageFilter,
  Dashboard,
  Register,
  SendEmail,
  ResetPassword,
  Config,
  ForbiddenWords,
} from "./Pages";
import Base from "./Components/Base";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={SendEmail} exact path="/esqueciminhasenha" />
        <Route component={ResetPassword} exact path="/password-reset" />
        <PrivateRoute component={Register} exact path="/cadastro" />
        <Base>
          <PrivateRoute component={Dashboard} exact path="/" />
          <PrivateRoute component={Images} exact path="/imagens" />
          <PrivateRoute component={MessageFilter} exact path="/mensagens" />
          <PrivateRoute component={Users} exact path="/usuarios/:id?" />
          <PrivateRoute component={Groups} exact path="/grupos" />
          <PrivateRoute component={Config} exact path="/configuracoes" />
          <PrivateRoute component={ForbiddenWords} exact path="/palavras" />
        </Base>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
