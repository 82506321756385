import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { SessionProvider } from './Context/session';
import { NotificationsProvider } from './Context/notification';

ReactDOM.render(
  <React.StrictMode>
    <SessionProvider>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </SessionProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
