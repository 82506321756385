import { React, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import api from "../../Services/api";
import { Background, Box } from "../../Components/UI";
import { Back, Loading } from "../../Components";
import { AiOutlineMail } from "react-icons/ai";

function SendEmail() {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    api
      .post("/sendpasswordrecoveryemail", { ...state })
      .then((response) => {
        if (response?.data) message.success(response.data.message);
        else
          message.success(
            "Confira a caixa de entrada do seu e-mail para prosseguir"
          );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          return message.error(error.response.data.message);
        }

        return message.error(
          "Não foi possível enviar e-mail, tente novamente mais tarde",
        );
      })
      .finally(() => setLoading(false));
  }

  return (
    <Background>
      {loading ? <Loading /> : null}
      <Box>
        <Back as={Link} to="/" />
        <h1>Redefinir senha</h1>
        <Form initialValues={{ remember: false }}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Insira um endereço de e-mail!" },
            ]}
          >
            <Input
              prefix={<AiOutlineMail className="site-form-item-icon" />}
              type="email"
              name="email"
              placeholder="E-mail"
              size="large"
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "230px" }}
              onClick={handleSubmit}
            >
              Enviar e-mail
            </Button>
          </Form.Item>
        </Form>
      </Box>
    </Background>
  );
}

export default SendEmail;
