import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ConfigProvider } from "antd";
import { defaultTheme } from "./Components/UI/Theme";
import Routes from "./routes";
import "./App.css";
import locale from "antd/lib/locale/pt_BR";

ConfigProvider.config({
  maxCount: 2,
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <ConfigProvider locale={locale}>
        <Routes />
      </ConfigProvider>
    </ThemeProvider>
  );
}

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 0;
  }
  
  // Sobreescrevendo cores do AntDesign
  .ant-switch-checked {
    background-color: #007965;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #007965;
    border-color: #007965;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #007965;
    background: #fff;
    border-color: #007965;
  }

  .ant-btn-primary {
    color: #fff;
    background: #007965;
    border-color: #007965;
  }

  .ant-btn-primary:active {
    color: #fff;
    background: #007965;
    border-color: #007965;
  }

  // estilização da scroll-bar
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  h1{
    font-weight: bold;
  }
`;

export default App;
