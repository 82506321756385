export const defaultTheme = {
  primary: "#007965",
  primaryHover: "#009b81",
  textOverPrimary: "#ffffff",
  textSecondary: "#101010",
  textGrey: "#A6A6A4",
  textGray: "#A6A6A4",
  backgroundGray: "#ccc",
  backgroundGrey: "#ccc",
  iconSize: "30px",
};
