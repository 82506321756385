import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { List, Tooltip, message } from "antd";
import { Container, Card } from "../../Components/UI";
import api from "../../Services/api";
import { useSession } from "../../Context/session";

import styled from "styled-components";
import { TeamOutlined } from "@ant-design/icons";
import { Loading } from "../../Components";

function Groups() {
  const history = useHistory();
  const { session } = useSession();
  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const configs = {
    headers: {
      authorization: session.accessToken,
    },
  };
  useEffect(() => {
    setLoading(true);

    api
      .get("/group", configs)
      .then((response) => {
        setGroup(response.data.groups);
      })
      .catch((error) => {
        if (error?.response?.data?.message)
          message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {loading ? <Loading /> : null}
        <h1 className="title">Grupos VilaBelve:</h1>
        <GroupContainer>
          <Card>
            <List
              itemLayout="horizontal"
              dataSource={group}
              size="large"
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <IconGroup>
                      <Tooltip title="Ver Participantes">
                        <TeamOutlined
                          onClick={() => {
                            history.push(`/usuarios/${item.id}`);
                          }}
                          color="black"
                        />
                      </Tooltip>
                    </IconGroup>,
                  ]}
                >
                  <List.Item.Meta
                    title={<h2 className="title">{item.name}</h2>}
                    description={<h3>{item.description}</h3>}
                  />
                  <div className="mobile">
                    <h3>{item.numMembers} membros</h3>
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </GroupContainer>
      </Container>
    </>
  );
}

const GroupContainer = styled(Container)`
  padding: 3% 5% 5% 5%;
  .mobile {
    display: block;
  }

  @media screen and (max-width: 660px) {
    .mobile {
      display: none;
    }
  }
`;

const IconGroup = styled.h1`
  color: #aaaaaa;
`;

export default Groups;
